<template>
<CardConf
    :title="$tc('consent-form.title')"
    :icon="'$form'"
    :size="600"
    :keys="'consentForm_active'"
    :activeted="true"
    v-if="$vuetify.breakpoint.mdAndUp"
  >
    <template>
      <v-row class="payment" align="center">
        <v-col tex cols="12" md="10">
          {{ $t("consent-form.consent_edit_ask") }}
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            :items="items"
            single-line
            dense
            outlined
            hide-details
            v-model="consentForm_edit"
          ></v-select> </v-col
      ></v-row>
      <v-row justify="center">
        <v-col :cols="6" class="text-center"> {{ $t("anex_info" , {name: $t('')}) }}</v-col>
        <v-col v-if="consentForm_edit" cols="6" class="text-center">
                {{ $t("initial_form") }}
        </v-col>
      </v-row>
      <template>
        <v-row
          v-for="(service, s) in services"
          :key="s"
          class="ma-0"
          justify="center"
        >
          <v-col :cols="3">
            {{
              service.artist_type_id
                ? $t("appointments.type." + service.name)
                : service.name
            }}
          </v-col>
          <v-col :cols="4" class="text-center">
            <ConsentFormAnexInfo :service="service"> </ConsentFormAnexInfo>
          </v-col>
          <v-col v-if="consentForm_edit" cols="5" class="text-center">
            <ConsentPiercer :service="service"> </ConsentPiercer>
          </v-col>
        </v-row>
      </template>
      <!--
      <template v-else>
        <v-row
          v-for="(service, s) in services"
          :key="s"
          class="ma-0"
          justify="center"
        >
          <v-col :cols="6" class="text-center">
            <ConsentFormAnexInfo :service="service"> </ConsentFormAnexInfo>
          </v-col>
          <v-col v-if="consentForm_edit" cols="6" class="text-center">
            <ConsentPiercer :service="service"> </ConsentPiercer>
          </v-col>
        </v-row>
      </template>
      -->
    </template>
  </CardConf>
  <CardConf
  v-else
    :title="$tc('consent-form.title')"
    :icon="'$form'"
    :size="600"
    :keys="'consentForm_active'"
    :activeted="true"
  >
    <v-row class="payment" align="center">
      <v-col tex cols="12" md="10">
        {{ $t("consent-form.consent_edit_ask") }}
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          :items="items"
          single-line
          dense
          outlined
          hide-details
          v-model="consentForm_edit"
        ></v-select> </v-col
    ></v-row>
   
        <v-card
         v-for="(service, s) in services"
        :key="s"
          align="center"
          :color="active"
          style="border: 1px solid var(--v-primary-base)"
          class="ma-4"
          
          elevation="5"
        >
          <v-card-title class="white--text text-uppercase">
            {{
              service.artist_type_id
                ? $t("appointments.type." + service.name)
                : service.name
            }}
          </v-card-title>

          <v-card-text :style="[{ height: size - 35 + 'px' }]">
            <v-row align="center" style="height: 100%">
              <v-col cols="12" class="text-center"> {{ $t("anex_info" , {name: $t('')}) }} </v-col>
              <v-col cols="12" class="text-center"><ConsentFormAnexInfo :service="service"> </ConsentFormAnexInfo></v-col>
            </v-row>
            <v-row v-if="consentForm_edit">
              <v-col cols="12" class="text-center">
                {{ $t("initial_form") }}
              </v-col>
               <v-col cols="12" class="text-center"> <ConsentPiercer :service="service"></ConsentPiercer></v-col>
            </v-row>
          </v-card-text>
        </v-card>
    
  </CardConf>
</template>
    <script>
import { mapActions } from "vuex";
export default {
  name: "ConsentFormCard",
  components: {
    CardConf: () => import("@/components/user/settings/Card"),
    EditForm: () =>
      import("@/components/user/settings/profile/EditConsentForm"),
    ConsentPiercer: () =>
      import("@/components/user/settings/profile/ConsentFormPiercerDefault"),

    ConsentFormAnexInfo: () =>
      import("@/components/user/settings/configuracion/services/ConsentForm"),
  },
  mounted() {
    this.fetchServices();
    this.form = this.$store.state.auth.user.studio.consent_form;
    if (this.form === null) {
      this.form = {
        customer: {
          legal_tutor: { tutor_type: "father" },
          user: {},
          medical_report: false,
          minus16_incapacitated: false,
          attached_information: false,
        },
        tattooer: { user: {} },
        studio: { user: {}, timetable: [[[]]] },
        available_dates: [{}],
        design: { photos: [], urls: [] },
        inktable: [],
        materialtable: [],
        session: { treatment_tips: false },
        clientSignature1: null,
        clientSignature2: null,
        tattooerSignature1: null,
      };
    }
  },
  methods: {
    ...mapActions("services", ["getServices"]),
    fetchServices() {
      this.getServices({}).then((response) => {
        this.services = response.data;
        console.log("SERVICES", this.services);
      });
    },

    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    hasService(service) {
      let services = JSON.parse(
        this.$store.getters["auth/getSetting"]("studio_services")
      );
      let s = services.find((x) => x.name === service);
      if (s) return true;
      return false;
    },
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    edit(valor) {
      this.disabled = valor;
    },
    ...mapActions("studios", [
      "consentFormSettings",
      "consentFormPiercerSettings",
    ]),
    saveConsentForm() {
      this.consentFormSettings(this.form).then(() => {
        this.$alert(this.$t("consent_form_update_success"), "", "");
        this.form = this.$store.state.auth.user.studio.consent_form;
        this.dialog = false;
      });
    },
    saveConsentFormPiercer(form) {
      this.consentFormPiercerSettings(form).then(() => {
        this.$alert(this.$t("consent_form_update_success"), "", "");

        this.dialog2 = false;
      });
    },

    del(index) {
      //console.log("delete", index);

      this.form.inktable.splice(index, 1);
    },
    delMaterial(index) {
      //console.log("delete", index);
      this.form.materialtable.splice(index, 1);
    },
    add() {
      this.form.inktable.push({});
    },
    addMaterial() {
      this.form.materialtable.push({});
    },
  },
  data() {
    return {
      disabled: false,
      services: [],
      form: null,
      items: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
      dialog: false,
      dialogConsentFormDefault: false,
    };
  },
  computed: {
    consentForm_edit: {
      get() {
        return this.$store.getters["auth/getSetting"]("consentForm_edit");
      },
      set(value) {
        //llamamos a la funcion de crear setting

        this.settingUpdate({
          key: "consentForm_edit",
          value,
        }).then((data) => {});
      },
    },
    isTattooer() {
      return (
        this.$store.getters["auth/isStudio"] ||
        (this.$store.getters["auth/isTattooer"] &&
          this.$store.state.auth.user.tattooer.type_artist.find(
            (x) => x.id === 1 || x === 1
          ))
      );
    },
    isPiercer() {
      return (
        this.$store.getters["auth/isStudio"] ||
        (this.$store.getters["auth/isTattooer"] &&
          this.$store.state.auth.user.tattooer.type_artist.find(
            (x) => x.id === 2 || x === 2
          ))
      );
    },
  },
};
</script>
    <style lang="sass">
#baseCons
  .row
    width: 100% !important
    margin-left: 0 !important
    .v-data-table
      table
        min-width: 650px
</style>
  </CardConf></template
>
